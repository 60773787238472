// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../assets/img/meeting.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("../../assets/img/stock_graph_orange_green.webp", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../assets/img/man_tablet_service.webp", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("../../assets/img/marketing_growth_solutions.webp", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("../../assets/img/web_development.webp", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("../../assets/img/woman_data_screens.webp", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("../../assets/img/blue_stock_graph.webp", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("../../assets/img/web_develoopment_v2.webp", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var ___HTML_LOADER_REPLACEMENT_6___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_6___);
var ___HTML_LOADER_REPLACEMENT_7___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_7___);
var code = " <section id=\"gallery-1234\"> <div class=\"cs-container\"> <div class=\"cs-content\"> <span class=\"cs-topper\">Our Gallery</span> <h2 class=\"cs-title\">Customizable Options</h2> <p class=\"cs-text\"> Showcase your brand with our printed marketing materials </p> </div> <div class=\"cs-gallery\"> <div class=\"cs-flex\"> <picture class=\"cs-picture cs-picture1\"> <img loading=\"lazy\" decoding=\"async\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"person\" width=\"360\" height=\"420\"/> </picture> <picture class=\"cs-picture cs-picture2\"> <img loading=\"lazy\" decoding=\"async\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" alt=\"person\" width=\"360\" height=\"240\"/> </picture> </div> <picture class=\"cs-picture cs-row\"> <img loading=\"lazy\" decoding=\"async\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" alt=\"person\" width=\"360\" height=\"520\"/> </picture> <div class=\"cs-flex\"> <picture class=\"cs-picture cs-picture1\"> <img loading=\"lazy\" decoding=\"async\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\" alt=\"person\" width=\"360\" height=\"420\"/> </picture> <picture class=\"cs-picture cs-picture2\"> <img loading=\"lazy\" decoding=\"async\" src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\" alt=\"person\" width=\"360\" height=\"240\"/> </picture> </div> <picture class=\"cs-picture cs-row\"> <img loading=\"lazy\" decoding=\"async\" src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\" alt=\"person\" width=\"360\" height=\"520\"/> </picture> <div class=\"cs-flex\"> <picture class=\"cs-picture cs-picture1\"> <img loading=\"lazy\" decoding=\"async\" src=\"" + ___HTML_LOADER_REPLACEMENT_6___ + "\" alt=\"person\" width=\"360\" height=\"420\"/> </picture> <picture class=\"cs-picture cs-picture2\"> <img loading=\"lazy\" decoding=\"async\" src=\"" + ___HTML_LOADER_REPLACEMENT_7___ + "\" alt=\"person\" width=\"360\" height=\"240\"/> </picture> </div> </div> </div> </section> ";
// Exports
export default code;