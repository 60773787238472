import './assets/css/styles.css';
import nav from './components/navigation/nav.html';
import './components/navigation/nav.css';
import hero from './components/hero/hero.html';
import './components/hero/hero.css';
import footer from './components/footer/footer.html';
import './components/footer/footer.css';
import info from './components/info/info.html';
import './components/info/info.css';
import cta from './components/cta/cta.html';
import './components/cta/cta.css';
import services1 from './components/services1/services1.html';
import './components/services1/services1.css';
import minigallery from './components/minigallery/minigallery.html';
import './components/minigallery/minigallery.css';
import casestudies from './components/casestudies/casestudies.html';
import './components/casestudies/casestudies.css';
import gallery from './components/gallery/gallery.html';
import './components/gallery/gallery.css';
import testimonials from './components/testimonials/testimonials.html';
import './components/testimonials/testimonials.css';
import ctaform from './components/ctaform/ctaform.html';
import './components/ctaform/ctaform.css';

document.body.innerHTML = nav
  + hero
  + info
  + cta
  + services1
  + cta
  + minigallery
  + cta
  + casestudies
  + cta
  + gallery
  + cta
  + testimonials
  + ctaform
  + footer;

// add classes for mobile navigation toggling
const CSbody = document.querySelector('body');
const CSnavbarMenu = document.querySelector('#cs-navigation');
const CShamburgerMenu = document.querySelector('#cs-navigation .cs-toggle');

CShamburgerMenu.addEventListener('click', () => {
  CShamburgerMenu.classList.toggle('cs-active');
  CSnavbarMenu.classList.toggle('cs-active');
  CSbody.classList.toggle('cs-open');
  // run the function to check the aria-expanded value
  ariaExpanded();
});

// checks the value of aria expanded on the cs-ul and changes it accordingly whether it is expanded or not
function ariaExpanded() {
  const csUL = document.querySelector('#cs-expanded');
  const csExpanded = csUL.getAttribute('aria-expanded');

  if (csExpanded === 'false') {
    csUL.setAttribute('aria-expanded', 'true');
  } else {
    csUL.setAttribute('aria-expanded', 'false');
  }
}

// This script adds a class to the body after scrolling 100px
// and we used these body.scroll styles to create some on scroll
// animations with the navbar

document.addEventListener('scroll', (e) => {
  const scroll = document.documentElement.scrollTop;
  if (scroll >= 100) {
    document.querySelector('body').classList.add('scroll');
  } else {
    document.querySelector('body').classList.remove('scroll');
  }
});

// mobile nav toggle code
const dropDowns = Array.from(
  document.querySelectorAll('#cs-navigation .cs-dropdown'),
);
for (const item of dropDowns) {
  const onClick = () => {
    item.classList.toggle('cs-active');
  };
  item.addEventListener('click', onClick);
}